<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="action-button mb-2"
      @click="showAddQuestionModal">
      <feather-icon icon="PlusIcon" />
      <b>Adicionar Pergunta</b>
    </b-button>

    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Procurar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Procurar"
            type="text"
            class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      id="table_quiz"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">

        <!-- Column: Code -->
        <span v-if="props.column.field === 'code'">
          {{ props.row.code }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-link v-b-tooltip.hover.v-secondary title="Deletar questão" @click="showDelQuestionModal(props.row.id, props.row.question)"> <feather-icon icon="Trash2Icon" class="mr-50" /> </b-link>
          <b-link v-b-tooltip.hover.v-secondary title="Visualizar respostas" @click="showListAnswersModal(props.row.id)"> <feather-icon icon="EyeIcon" class="mr-50" /> </b-link>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ props.total }} Resultados </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!---- del question ---->
    <b-modal
      ref="modal-del-question"
      v-model="show"
      cancel-variant="outline-secondary"
      ok-title="Confirmar"
      cancel-title="Fechar"
      centered
      title="Remover questão">
      Você excluirá a seguinte pergunta:
      <b> {{ question.question }}</b>
      <template #modal-footer>
        <div class="w-100">

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" size="sm" class="float-right ml-1" @click="show = false">
            Fechar
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="float-right" @click="deleteQuestion(question.id)">
            Excluir
          </b-button>

        </div>
      </template>
    </b-modal>
    <!---- del question ---->

    <!---- add question ---->
    <b-modal
      id="modal-add-question"
      ref="modal-add-question"
      cancel-variant="outline-secondary"
      ok-title="Confirmar"
      cancel-title="Fechar"
      centered
      title="Adcionar Pergunta">
      <b-form>

        <b-form-group>
          <label for="question-code">Código:</label>
          <b-form-input id="question-code" v-model="question.code" type="text" placeholder="0123" readonly />
        </b-form-group>

        <b-form-group>
          <label for="question">Pergunta:</label>
          <b-form-input id="question" v-model="question.question" type="text" placeholder="Insira a pergunta" />
        </b-form-group>

      </b-form>

      <template #modal-footer>
        <div class="w-100">

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" size="sm" class="float-right ml-1" @click="hideAddQuestionModal">
            Fechar
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="float-right" @click="registerQuestion()">
            Cadastrar
          </b-button>

        </div>
      </template>
    </b-modal>
    <!---- add question ---->

    <!---- add answers ---->
    <b-modal
      ref="add-answers-modal"
      cancel-variant="outline-secondary"
      ok-title="Confirmar"
      cancel-title="Fechar"
      centered
      title="Adcionar Resposta">
      <b-form>
        <input v-model="answers.questionProfileId" type="hidden">

        <b-form-group>
          <label for="response">Resposta:</label>
          <b-form-input id="response" v-model="answers.response" type="text" placeholder="Resposta" required />
        </b-form-group>

        <b-form-group>
          <label for="score">Pontuação:</label>
          <b-form-input id="score" v-model="answers.score" type="text" placeholder="1" required />
        </b-form-group>

      </b-form>
      <template #modal-footer>
        <div class="w-100">

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" ariant="secondary" size="sm" class="float-right ml-1" @click="hideAddAnswersModal">
            Fechar
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="float-right" @click="registerAnswers()">
            Cadastrar
          </b-button>

        </div>
      </template>
    </b-modal>
    <!---- add answers ---->

    <!---- list answers ---->
    <b-modal
      ref="list-answers-modal"
      cancel-variant="outline-secondary"
      ok-title="Confirmar"
      cancel-title="Fechar"
      centered
      size="lg"
      title="Listar Resposta">

      <template>

          <div v-for="(value, index) in vListAnswers" :key="index" class="mb-2 mt-2 d-flex">

            <b-col class="align-middle">
              <b>{{ index + 1 }}) Resposta:</b> {{ value.questionAnswer.response }}
            </b-col>

            <b-col>
              <b-button size="sm" class="action-button btn btn-danger float-right" @click="$bvModal.show(removeAnswerModalId(index + 1))">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>

            <b-modal
              :id="removeAnswerModalId(index + 1)"
              ref="del-answers-modal"
              cancel-variant="outline-secondary"
              ok-title="Confirmar"
              cancel-title="Fechar"
              centered
              title="Remover Resposta">
              Você excluirá a seguinte respota:
              <b>{{ value.questionAnswer.response }}</b>

              <template #modal-footer>
                <div class="w-100">

                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" size="sm" class="float-right ml-1" @click="$bvModal.hide(removeAnswerModalId(index + 1))">
                    Fechar
                  </b-button>

                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" size="sm" class="float-right" @click="deleteAnswers(value.questionAnswer.id)">
                    Excluir
                  </b-button>

                </div>
              </template>
            </b-modal>

          </div>

      </template>

      <template #modal-footer>
        <div class="w-100">

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" size="sm" class="float-right ml-1" @click="hideListAnswersModal">
            Fechar
          </b-button>

          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" size="sm" class="action-button float-right" @click="showAddAnswersModal(answers.questionProfileId)">
            <feather-icon icon="PlusIcon" />
            <b>Adicionar Resposta</b>
          </b-button>

        </div>
      </template>

    </b-modal>
    <!---- list answers ---->

  </div>
</template>

<script>
import {
  BCol,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  VBModal,
  BLink,
  BPagination,
  BFormSelect,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BCol,
    BButton,
    FeatherIcon,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      question: {
        code: '',
        question: '',
        id: '',
      },

      answers: {
        response: '',
        score: '',
        questionProfileId: '',
      },

      vListAnswers: [],

      pageLength: 10,
      dir: false,
      searchTerm: '',
      columns: [
        {
          label: 'Código',
          field: 'code',
        },
        {
          label: 'Questão',
          field: 'question',
        },
        {
          label: 'Ação',
          field: 'action',
        },
        // 'action',
      ],
      rows: [],
      show: false,

      error: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async mounted() {
    this.listQuestion()
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  methods: {
    async registerQuestion() {
      try {
        const questionProfiles = {
          question: this.question.question,
        }
        const response = await this.$http.post('/api/services/app/QuestionProfiles/CreateOrEdit',
          questionProfiles,
          {
            withCredentials: false,
            timeout: 30000,
            validateStatus: () => true,
          })

        if (response.data.error) {
          this.error = response.data.error.message
          this.msgError()
          return
        }

        this.error = ''

        this.msgSuccessRegister()
        this.listQuestion()
        this.hideAddQuestionModal()
        // setTimeout(() => {
        //   this.$router.push({ name: 'registered' })
        // }, 2700)
      } catch (error) {
        this.errors = error.message
      }
    },

    async registerAnswers() {
      try {
        const questionAnswers = {
          response: this.answers.response,
          score: this.answers.score,
          active: true,
          questionProfileId: this.answers.questionProfileId,
        }

        const response = await this.$http.post('/api/services/app/QuestionAnswers/CreateOrEdit',
          questionAnswers,
          {
            withCredentials: false,
            timeout: 30000,
            validateStatus: () => true,
          })

        if (response.data.error) {
          this.error = response.data.error.message
          this.msgError()
          return
        }

        this.error = ''
        this.listAnswers()
        this.msgSuccessRegister()
        this.hideAddAnswersModal()
        // setTimeout(() => {
        //   this.$router.push({ name: 'registered' })
        // }, 2700)
      } catch (error) {
        this.errors = error.message
      }
    },

    async listQuestion() {
      const resQuestion = await this.$http.get(
        '/api/services/app/QuestionProfiles/GetAll',
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
      this.rows = resQuestion.data.result.items.map(res => ({
        code: res.questionProfile.code,
        question: res.questionProfile.question,
        id: res.questionProfile.id,
      }))
    },

    async listAnswers() {
      const resAnswers = await this.$http.get(
        `/api/services/app/QuestionAnswers/GetAll?QuestionProfileId=${this.answers.questionProfileId}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )

      if (resAnswers) {
        this.vListAnswers = resAnswers.data.result.items
        return true
      }
      return []
    },

    async deleteQuestion(id) {
      await this.$http.delete(
        `/api/services/app/QuestionProfiles/Delete?Id=${id}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )

      this.msgSuccess()
      this.listQuestion()
      this.hideDelQuestionModal()
    },

    async deleteAnswers(id) {
      await this.$http.delete(
        `/api/services/app/QuestionAnswers/Delete?Id=${id}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )

      this.msgSuccess()
      this.listAnswers()
      this.hideDelQuestionModal()
    },

    showAddQuestionModal() {
      this.question.code = ''
      this.question.question = ''
      this.$refs['modal-add-question'].show()
    },

    hideAddQuestionModal() {
      this.$refs['modal-add-question'].hide()
    },

    showDelQuestionModal(id, question) {
      this.question.id = id
      this.question.question = question
      this.$refs['modal-del-question'].show()
    },
    hideDelQuestionModal() {
      this.$refs['modal-del-question'].hide()
    },

    showAddAnswersModal(id) {
      this.answers.code = ''
      this.answers.response = ''
      this.answers.score = ''
      this.answers.questionProfileId = id

      this.$refs['add-answers-modal'].show()
    },
    hideAddAnswersModal() {
      this.$refs['add-answers-modal'].hide()
    },

    showListAnswersModal(id) {
      this.answers.questionProfileId = id
      this.listAnswers()
      this.$refs['list-answers-modal'].show()
    },
    hideListAnswersModal() {
      this.$refs['list-answers-modal'].hide()
    },

    hideDelAnswersModal() {
      this.$refs['del-answers-modal'].hide()
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados excluídos com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // success
    msgSuccessRegister() {
      this.$swal({
        icon: 'success',
        title: 'Dados gravados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    removeQuestionModalId: ({ codigo }) => `modal-remove-question${codigo}`,
    removeAnswerModalId: index => `modal-remove-answer${index}`,
  },
}
</script>

<style lang="scss">
body {
    background-color: #f8f8f8 !important;
}
.action-button {
  &:not(:first-child) {
    margin-left: 10px;
  }
}
.d-flex {
  display: flex;
}
 table {
  width: 100%;
  max-width: 100%;
 }

 #table_quiz tr {
  border: 0;
  display: block;
  margin: 15px 8px;
  border: 1px #fff solid;
  border-radius: 10px;
  }

 #table_quiz td {
  padding: 5px;
}
 #table_quiz tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

 #table_quiz  .vgt-table.bordered td,  #table_quiz .vgt-table.bordered th {
  border: #fff !important;
}

 #table_quiz table.vgt-table td {
   padding: 1.5em 0.75em 1.5em 0.75em !important;
 }

 #table_quiz .vgt-left-align {
   text-align: left !important;
 }

 #table_quiz table.vgt-table td {
   vertical-align: baseline !important;
   color: #6e6b7b;
 }
 #table_quiz table.vgt-table thead {
   display: none;
 }
 #table_quiz table.vgt-table tbody {
   font-size: 13px;
 }
 #table_quiz table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
 }
 #table_quiz table tbody th { padding-left: 13px; }
 #table_quiz table tbody td { max-width: 150px; min-width: 100px; }
 #table_quiz table tbody td:first-child { max-width: 100px; min-width: 100px; }
 #table_quiz table tbody td:nth-child(2) { max-width: 500px; min-width: 500px; }
 #table_quiz table tbody td:nth-child(3) { max-width: 80px; min-width: 80px; float: right; margin-top: -58px; }
 #table_quiz a { color: #828282; }
 #table_quiz a:hover { color: #F8AC18; }

 #table_quiz td[colspan="4"] {
   text-align: center;
   display: inline;
   white-space: nowrap;
   overflow: hidden;
   width: 100%;
 }
</style>
